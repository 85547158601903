<template>
	<v-app>
		<v-main color="white" class="bg-white">
			<v-container class="container-90">
				<Topmenubarsecond />
				<v-row class="min-h-100vh">
					<v-col cols="12" md="4" class="my-auto">
						<h1 class="display-2">Automatiseer je bedrijf aan de voorkant van een samenwerking.</h1>
						<br />
						<p class="text-md-h6 font-weight-regular">Met Kyano Orders Automatiseer je enkele processen zoals inschrijvingen, donaties, productenverkoop, licenties, offerte aanvragen en meer.</p>
						<v-row class="my-5">
							<v-col cols="12">
								<v-btn href="" large elevation="0" color="secondary" class="mr-3">Probeer 14 dagen gratis</v-btn>
								<v-btn href="" large elevation="0" outlined color="primary">Inloggen</v-btn>
							</v-col>
						</v-row>
						<v-divider class="my-5"></v-divider>

						<v-btn color="prmiary" text href="https://kyano.app/orders">Meer informatie over Kyano Orders</v-btn>
					</v-col>
					<v-col offset-md="3" cols="12" md="5" class="my-auto">
						<v-img src="../assets/process.svg"></v-img>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import Topmenubarsecond from "../components/Topmenubarsecond.vue"
export default {
	components: {
		Topmenubarsecond
	},
	data() {
		return {

		};
	},

};
</script>
<style>
</style>