<template>
  <v-app-bar
    color="systemwhite"
    clipped-left
    app
    elevate-on-scroll
    fade-img-on-scroll
  >
  <a href="/">
    <v-img
      class="m-auto mr-10"
      max-width="165"
      width="165"
      max-height="30"
      src="../assets/kyano-order-logo-dark.svg"
    ></v-img>
</a>

    <v-spacer></v-spacer>

    <Topmenuapps />
    <Topmenuprofile />
  </v-app-bar>
</template>

<script>
import Topmenuapps from "./Topmenuapps.vue";
import Topmenuprofile from "./Topmenuprofile.vue";

export default {
  components: { Topmenuprofile, Topmenuapps },
  data: () => ({
    props: {
      title: Array,
    },
  }),
};
</script>
